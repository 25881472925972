import React, { Fragment } from 'react';
import { withAlert } from 'react-alert';
import { SessionUtil, DateUtil, GridUtil } from 'utils';
import { Trans } from 'react-i18next';

import { Const, Server } from 'components/Properties';
import { Modal, Button, Select, DatePicker } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import qs from 'qs';
import i18n from 'i18next';

import { moment } from 'components';
import NumberFormat from 'react-number-format';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
class HatchingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      hatchDate: DateUtil.today(),
      availableQty: 0,
      tot: 0,
      form: [],
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if (prevProps.rowData != this.props.rowData && this.props.iuFlag === 'I') {
      this.setState(
        {
          form: this.props.rowData,
          availableQty:
            Number(that.props.rowData.fertileQty) -
            Number(that.props.cuttingQty),
          tot: 0,
        },
        function () {}
      );
    }
    if (prevProps.rowData != this.props.rowData && this.props.iuFlag === 'U') {
      this.setState(
        {
          form: this.props.rowData,
          availableQty:
            Number(that.props.rowData.fertileQty) -
            Number(that.props.cuttingQty),
          tot:
            Number(this.props.rowData.byProd) +
            Number(this.props.rowData.culling) +
            Number(this.props.rowData.ps) +
            Number(this.props.rowData.broiler),
        },
        function () {}
      );
    }
  }

  //Input field 만들기 - text, Number, Time, Data
  //ty => N:NumberFormat  T:TimePicker  text
  renderInput = (inputName, ty, dec, len) => {
    let cl = len ? 'input_type1 w' + len : 'input_type1';
    let that = this;

    if (ty && ty === 'N' && inputName === 'fertileQty') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e, ty)}
          disabled={true}
        />
      );
    }
    if (inputName === 'cuttingQty') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className={cl}
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          getInputRef={(el) => (this[inputName] = el)}
          value={this.props.cuttingQty}
          onChange={(e) => this.setOnChange(e, ty)}
          disabled={true}
        />
      );
    }
    if (inputName === 'availableQty') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <input
          type="text"
          className="input_type1"
          value={
            this.state.availableQty
              ? this.state.tot + '/' + this.state.availableQty || ''
              : ''
          }
          readOnly
        />
      );
    } else if (ty && ty === 'N') {
      cl = len ? 'input_base w' + len : 'input_base';
      return (
        <NumberFormat
          style={{ textAlign: 'right' }}
          className="input_base w4"
          thousandSeparator={true}
          decimalScale={dec ? dec : 0}
          name={inputName}
          //ref={this[inputName]}
          getInputRef={(el) => (this[inputName] = el)}
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : 0
          }
          onChange={(e) => this.setOnChange(e, ty)}
        />
      );
    } else if (ty && ty === 'T') {
      return (
        <TimePicker
          //defaultValue={moment('12:08', 'HH:mm')}
          className={cl}
          format={'HH:mm'}
          ref={this[inputName]}
          value={
            this.state.form[inputName]
              ? moment(this.state.form[inputName], 'HH:mm') || ''
              : null
          }
          onChange={(date, dateString) =>
            this.setOnChangeTime(date, dateString, inputName)
          }
        />
      );
    } else if (ty && ty === 'S') {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          value={this.state.rcvDate}
          disabled={true}
        />
      );
    } else {
      return (
        <input
          type="text"
          name={inputName}
          className={cl}
          ref={this[inputName]}
          maxLength="100"
          value={
            this.state.form[inputName] ? this.state.form[inputName] || '' : ''
          }
          onChange={(e) => this.setOnChange(e)}
        />
      );
    }
  };

  setOnChange = (e, params) => {
    let that = this;
    const col = e.target.name ? e.target.name : '';
    let val = e.target.value;
    let tot = 0;
    params.type = params.type ? params.type.toUpperCase() : '';

    if (params.type === 'N') {
      val = val.replace(/,/gi, '');
    }

    console.log('val', val);

    this.setState(
      {
        form: {
          ...this.state.form,
          [col]: col,
          [col]: val,
        },
      },
      function () {
        if (this.state.form.ompalQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.ompalQty).replace(/,/gi, ''));
        if (this.state.form.psdocFQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.psdocFQty).replace(/,/gi, ''));
        if (this.state.form.deadQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.deadQty).replace(/,/gi, ''));
        if (this.state.form.spQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.spQty).replace(/,/gi, ''));
        if (this.state.form.disQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.disQty).replace(/,/gi, ''));
        if (this.state.form.defQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.defQty).replace(/,/gi, ''));
        if (this.state.form.blotQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.blotQty).replace(/,/gi, ''));
        if (this.state.form.weakQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.weakQty).replace(/,/gi, ''));
        if (this.state.form.psdocMQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.psdocMQty).replace(/,/gi, ''));
        if (this.state.form.a1Qty)
          tot =
            Number(tot) +
            Number(String(this.state.form.a1Qty).replace(/,/gi, ''));
        if (this.state.form.a2Qty)
          tot =
            Number(tot) +
            Number(String(this.state.form.a2Qty).replace(/,/gi, ''));
        if (this.state.form.sexQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.sexQty).replace(/,/gi, ''));
        if (this.state.form.polosQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.polosQty).replace(/,/gi, ''));
        if (this.state.form.bmQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.bmQty).replace(/,/gi, ''));
        if (this.state.form.bQty)
          tot =
            Number(tot) +
            Number(String(this.state.form.bQty).replace(/,/gi, ''));

        that.setState(
          {
            tot: tot,
          },
          function () {}
        );
      }
    );
  };

  fnClickSaveBtn = () => {
    this.setState({ visible: false }, function () {});

    let that = this;
    let data = this.state.form;

    data.farmCode = SessionUtil.getFarmCode();
    data.hatcheryId = SessionUtil.getHatcheryId();
    data.dateFormat = SessionUtil.getDateFormat();
    data.iuFlag = this.props.iuFlag;

    if (!data.hatchDate) {
      data.hatchDate = this.state.hatchDate;
    }

    if (data.farmCode === 'undefined') {
      data.farmCode = '';
    }

    if (!data.disQty) data.disQty = '0';
    if (!data.defQty) data.defQty = '0';
    if (!data.blotQty) data.blotQty = '0';
    if (!data.deadQty) data.deadQty = '0';
    if (!data.ompalQty) data.ompalQty = '0';
    if (!data.weakQty) data.weakQty = '0';
    if (!data.psdocFQty) data.psdocFQty = '0';
    if (!data.psdocMQty) data.psdocMQty = '0';
    if (!data.sexQty) data.sexQty = '0';
    if (!data.spQty) data.spQty = '0';
    if (!data.a2Qty) data.a2Qty = '0';
    if (!data.a1Qty) data.a1Qty = '0';
    if (!data.bmQty) data.bmQty = '0';
    if (!data.bQty) data.bQty = '0';
    if (!data.polosQty) data.polosQty = '0';

    console.log('data', data);

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/hatching/saveHatchingInfo.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        that.hideModal();
        that.props.alert.show(i18n.t('Saved'));
        that.props.fnSetRegDate();
        that.fnClear();
      })
      .catch(function (error) {
        that.hideModal();
        that.props.alert.show(i18n.t('Save failed'));
        return [];
      });
  };

  fnClear = (e) => {
    if (this.props.iuFlag === 'I') {
      this.setState(
        {
          form: [],
          tot: 0,
        },
        function () {}
      );
    }
    if (this.props.iuFlag === 'U') {
      this.setState(
        {
          form: this.props.rowData,
          tot: this.state.availableQty,
        },
        function () {}
      );
    }
  };

  fnClickCancel = (e) => {
    this.props.fnClickCancel();
    this.fnClear();
  };

  onDateChange = (date, dateString, name) => {
    this.setState(
      { form: { ...this.state.form, [name]: dateString } },
      function () {}
    );
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState(
      {
        visible: false,
      },
      function () {}
    );
  };

  confirm = () => {
    if (
      this.state.tot !==
      Number(this.props.rowData.fertileQty) - Number(this.props.cuttingQty)
    ) {
      this.props.alert.show(i18n.t('Please check quantity.')); // 수량을 확인하세요
      return false;
    }

    var hatchingDate = new Date(this.props.rowData.candlingDate);
    hatchingDate.setDate(hatchingDate.getDate() + 3);

    var year = hatchingDate.getFullYear();
    var month = hatchingDate.getMonth() + 1;
    var day = hatchingDate.getDate();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    hatchingDate = year + '-' + month + '-' + day;

    if (hatchingDate != this.state.form.hatchDate) {
      Modal.confirm({
        content:
          "The standard hatching date is '" +
          hatchingDate +
          "'. Would you like to save it anyway?", // 기준 날짜는 'xxxx-xx-xx' 입니다. 그래도 저장하시겠습니까?
        visible: this.state.visible,
        okText: 'Save',
        cancelText: 'Cancel',
        onOk: this.fnClickSaveBtn,
        onCancel: this.hideModal,
      });
    } else {
      this.fnClickSaveBtn();
    }
  };

  render() {
    if (!this.props.hatchingFormView) return null;
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <div className="con_box">
          {/* write&view */}
          <h3>
            <Trans>Hatching Info</Trans>
          </h3>
          <div className="layer_list writelist">
            <div className="board_list2">
              <table>
                <colgroup>
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '20%' }} />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <Trans>Hatching Date</Trans>
                    </th>
                    <td>
                      <DatePicker
                        name="hatchDate"
                        style={{ width: 120 }}
                        value={
                          this.state.form.hatchDate
                            ? moment(this.state.form.hatchDate)
                            : moment(DateUtil.today())
                        }
                        onChange={(date, dateString) =>
                          this.onDateChange(date, dateString, 'hatchDate')
                        }
                        allowClear={false}
                      />
                    </td>
                    <th>
                      <Trans>Total</Trans>
                    </th>
                    <td>
                      <span>
                        {this.state.tot.toLocaleString() +
                          '/' +
                          this.state.availableQty.toLocaleString()}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>ByProduct</Trans>
                    </th>
                    <td colSpan="3">
                      <label htmlFor="" className="wtitle">
                        <Trans>DIS</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'disQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        minVal: 0,
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}

                      <label htmlFor="" className="wtitle">
                        <Trans>Bloat</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'blotQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        minVal: 0,
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}

                      <label htmlFor="" className="wtitle">
                        <Trans>DEF</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'defQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}

                      <label htmlFor="" className="wtitle">
                        <Trans>Dead</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'deadQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Culling</Trans>
                    </th>
                    <td colSpan="3">
                      <label htmlFor="" className="wtitle">
                        <Trans>Omphalitis</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'ompalQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}

                      <label htmlFor="" className="wtitle">
                        <Trans>Weak</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'weakQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>PS DOC</Trans>
                    </th>
                    <td colSpan="3">
                      <label htmlFor="" className="wtitle">
                        <Trans>PS Female</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'psdocFQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        minVal: 0,
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}

                      <label htmlFor="" className="wtitle">
                        <Trans>PS Male</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'psdocMQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Broiler DOC</Trans>
                    </th>
                    <td colSpan="3">
                      <label htmlFor="" className="wtitle">
                        <Trans>S/P</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'spQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}

                      <label htmlFor="" className="wtitle">
                        <Trans>A2 </Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'a2Qty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}

                      <label htmlFor="" className="wtitle">
                        <Trans>Sex </Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'sexQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                      <br />
                      <label htmlFor="" className="wtitle">
                        <Trans>A1</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'a1Qty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                      <label htmlFor="" className="wtitle">
                        <Trans>BM</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'bmQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                      <label htmlFor="" className="wtitle">
                        <Trans>B</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'bQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                      <label htmlFor="" className="wtitle">
                        <Trans>Polos</Trans>
                      </label>
                      {GridUtil.renderInput({
                        inputName: 'polosQty', //*** */
                        type: 'N', //*** */
                        minVal: 0,
                        that: this, //*** */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Remarks</Trans>
                    </th>
                    <td colSpan="3">
                      {GridUtil.renderInput({
                        inputName: 'remarks', //*** */
                        type: '', //*** */
                        //dec: 2,
                        maxLength: 1000,
                        //maxVal: 999.99,
                        //width: 70, // px제외 : 없으면 30 */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        that: this, //*** */
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Discard</Trans>
                    </th>
                    <td>
                      {GridUtil.renderInput({
                        inputName: 'discardQty', //*** */
                        type: 'N', //*** */
                        that: this, //*** */
                        minVal: 0,
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        width: 120, // px제외 : 없으면 100% */
                        disabled: true,
                      })}
                    </td>

                    <th>
                      <Trans>Discard Remarks</Trans>
                    </th>
                    <td colSpan="3">
                      {GridUtil.renderInput({
                        inputName: 'discardRem', //*** */
                        type: '', //*** */
                        //dec: 2,
                        maxLength: 1000,
                        //maxVal: 999.99,
                        //width: 70, // px제외 : 없으면 30 */
                        statsForm: this.state.form, //*** */
                        setOnChange: this.setOnChange, //*** */
                        that: this, //*** */
                        disabled: true,
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p style={{ color: 'red' }}>
              <span>*</span>
              <Trans>Total</Trans> = <Trans>Fertile Qty</Trans> -{' '}
              <Trans>Cutting</Trans>
            </p>

            <div className="txt_center mT40">
              <button
                className="btn_gray"
                onClick={(e) => this.fnClickCancel()}
              >
                <Trans>Cancel</Trans>
              </button>
              {this.props.iuFlag === 'I' ? (
                <button className="btn_save" onClick={this.confirm}>
                  <Trans>Save</Trans>
                </button>
              ) : null}
            </div>
          </div>
          {/* write&view end*/}
        </div>

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
export default withAlert()(HatchingForm);
