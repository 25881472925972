import React, { Fragment, Component } from 'react';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { Const, Server } from 'components/Properties';
import GrowingItemList from 'components/mobile/growing/GrowingItemList';
import MobileHeader from 'components/mobile/header/MobileHeader';
import { observer, inject } from 'mobx-react';
import i18n from 'i18next';
import ModalContainer from 'containers/pages/mobile/common/ModalContainer';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mProjectStore: stores.MprojectStore,
  mGrowingStore: stores.MGrowingStore,
}))
@observer
class Growing extends Component {
  componentDidMount() {
    this.fnSelectGrowingList();
  }

  componentWillUnmount() {
    const { mGrowingStore } = this.props;
    mGrowingStore.initStore();
  }

  //육성일지 리스트 불러오기(입고된 날짜를 기준으로 시작)
  fnSelectGrowingList = () => {
    let params = {};

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.countryCode = SessionUtil.getCountryCode();
    params.hatcheryId = SessionUtil.getHatcheryId();
    params.userId = SessionUtil.getUserId();
    params.projCode = SessionUtil.getProjCode();
    params.startDate = moment(sessionStorage.getItem('startDate')).format(
      'YYYY[-]MM[-]DD'
    );

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/broiler/selectGlowingLogList.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.fnSelectSuccess(response.data);
      })
      .catch(function (error) {
        return [];
      });
  };

  //취득한 육성일지 리스트 정보를 View 에 맞게 가공합니다.
  fnSelectSuccess = (value) => {
    const { mGrowingStore } = this.props;

    var logListOrigin = value.rows1;
    var logList = [];

    for (var i = 0; i < logListOrigin.length; i++) {
      logList.push(logListOrigin[i]);
    }

    logList.reverse();

    var stock = Number(value.stock[0].stock ? value.stock[0].stock : 0);
    logList[0].prevPop = stock;
    logList[0].population =
      stock -
      Number(logList[0].deathQty) -
      Number(logList[0].cullingQty) -
      Number(logList[0].transferQty);

    logList.forEach((row, i) => {
      if (i === 0) {
        row.iuFlag = row.workDate == undefined ? 'I' : 'U';
        return;
      }
      row.iuFlag = row.workDate == undefined ? 'I' : 'U';
      row.prevPop = logList[i - 1].population;
      row.population =
        row.prevPop -
        Number(row.deathQty) -
        Number(row.cullingQty) -
        Number(row.transferQty);
    });
    mGrowingStore.setGrowingList(logList);
    this.fnFindLatestIndex(logList);
  };

  fnFindLatestIndex = (logList) => {
    const { mGrowingStore } = this.props;
    let idx = [];
    let maxIdx = 0;
    logList.forEach((row, i) => {
      if (row.workDate !== undefined) {
        idx.push(i);
      }
    });
    if (idx.length >= 1) {
      maxIdx = Math.max.apply(null, idx);
    }
    mGrowingStore.setLatestWorkDate(maxIdx);
  };

  //육성일지 작성 및 수정 이벤트
  fnSetGrowingList = (value, i, prevValue) => {
    const { mStore, mGrowingStore } = this.props;

    //전일자 BW 셋팅
    if (prevValue != undefined) {
      if (prevValue.bw != undefined && prevValue.bw > 0) {
        mGrowingStore.setBw(prevValue.bw);
      } else {
        mGrowingStore.setBw(0);
      }
    }

    if (value.iuFlag == 'U') {
      mStore.setIuFlagUpdate();
    } else {
      mStore.setIuFlagInsert();
    }
    sessionStorage.setItem('workDate', value.wdate);

    let logList = mGrowingStore.growingList;

    //가장 최근에 작성된 육성일지를 찾습니다.
    let idx = [];
    let maxIdx = 0;
    logList.forEach((row, i) => {
      if (row.workDate !== undefined && row.workDate < value.wdate) {
        idx.push(i);
      }
    });
    if (idx.length >= 1) {
      maxIdx = Math.max.apply(null, idx);
    }
    //선택한 날짜 이전의 육성일지 기록
    if (i > 0) {
      //전일 육성일지 미작성시 작성불가
      if (
        mGrowingStore.growingList[i - 1].iuFlag == 'I' &&
        value.iuFlag == 'I'
      ) {
        mStore.setModalOpen(
          'A',
          null,
          i18n.t('You have to fill out previous daily log.')
        );
        return false;
      }

      sessionStorage.setItem(
        'prevGrowLogWorkdate',
        mGrowingStore.growingList[maxIdx].wdate
      );
      sessionStorage.setItem('prevGrowLogAgeDay', maxIdx);
      sessionStorage.setItem(
        'prevGrowLogPopulation',
        mGrowingStore.growingList[maxIdx].population
      );
    }

    if (i == 0) {
      sessionStorage.setItem(
        'prevGrowLogWorkdate',
        mGrowingStore.growingList[i].wdate
      );
      sessionStorage.setItem('prevGrowLogAgeDay', i);
      sessionStorage.setItem(
        'prevGrowLogPopulation',
        mGrowingStore.growingList[i].population
      );
    }

    this.props.history.push('/mobile/broiler/growForm');
  };

  fnPushBack = () => {
    this.props.history.push(Const.MOBILE_LOGINED_PAGE);
  };

  render() {
    const { mStore, mProjectStore, mGrowingStore } = this.props;

    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}

        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
        </div>

        <div className="mobile_content_nofooter">
          <div className="recebox2" style={{ margin: '0px' }}>
            <ul>
              <li>
                <span>
                  <Trans>Last Work Date</Trans>
                </span>
                {mGrowingStore.growingList.length == 0 ||
                mGrowingStore.latestWorkDate <= 0
                  ? moment(sessionStorage.getItem('startDate')).format(
                      'YYYY[-]MM[-]DD'
                    )
                  : moment(
                      mGrowingStore.growingList[mGrowingStore.latestWorkDate]
                        .workDate
                    ).format('YYYY[-]MM[-]DD')}
              </li>
              <li>
                <span>
                  <Trans>Age</Trans>
                </span>

                {sessionStorage.getItem('ageDay') == 'undefined'
                  ? 0
                  : sessionStorage.getItem('ageDay')}
              </li>
              <li>
                <span>
                  <Trans>Population</Trans>
                </span>
                {mGrowingStore.growingList.length == 0 ||
                mGrowingStore.latestWorkDate < 0
                  ? mProjectStore.selectedProject.recvQty
                  : mGrowingStore.growingList[mGrowingStore.latestWorkDate]
                      .population}
              </li>
            </ul>
          </div>
          <GrowingItemList
            growList={mGrowingStore.growingList}
            fnSetGrowingList={this.fnSetGrowingList}
            mProjectStore={mProjectStore}
            latestWorkDate={mGrowingStore.latestWorkDate}
          />
        </div>

        <ModalContainer />
      </Fragment>
    );
  }
}

export default Growing;
