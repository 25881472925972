import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SessionUtil, DateUtil } from 'utils';
import { Trans } from 'react-i18next';

import { moment } from 'components';

import { Const, Server } from 'components/Properties';
import axios from 'axios';
import qs from 'qs';

import i18n from 'i18next';
import {
  AgGridReact,
  AllModules,
  isRowSelected
} from 'components/commons/ag-grid/AgGridUtil';
import scrollToComponent from 'react-scroll-to-component';

import HatchingList from './HatchingList';
import HatchingForm from './HatchingForm';
import SettingList from './SettingList';

import '../Hatchery.module.css';

/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 */
export default class Hatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iuFlag: 'I',
      rowData: [],
      hatchingFormView: false,
      cuttingQty: '',
      regDate: DateUtil.toDateTimeString()
    };
  }

  componentDidMount() {
    // 달력에 로케일 적용
    moment.locale(SessionUtil.getLang());
  }

  fnSetRegDate = () => {
    this.setState({
      regDate: DateUtil.toDateTimeString(),
      hatchingFormView: false
    });
  };

  fnClickHatching = data => {
    let that = this;
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/hatching/getFertileCuttingQty.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        that.setState(
          { cuttingQty: response.data.rows1[0].fertileCuttingQty },
          function() {
            that.setState(
              { hatchingFormView: true, rowData: data, iuFlag: 'I' },
              function() {
                scrollToComponent(this['hatchingFormView'], {
                  offset: 0,
                  align: 'top',
                  duration: 1000
                });
              }
            );
          }
        );
      })
      .catch(function(error) {
        return [];
      });
  };

  fnClickCancel = () => {
    this.setState({ hatchingFormView: false }, function() {});
  };

  fnClickModify = data => {
    let that = this;
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/hatchery/hatching/getFertileCuttingQty.do',
      data: qs.stringify(data),
      withCredentials: true,
      credentials: 'same-origin'
    })
      .then(function(response) {
        that.setState(
          { cuttingQty: response.data.rows1[0].fertileCuttingQty },
          function() {
            that.setState(
              {
                rowData: data,
                hatchingFormView: true,
                iuFlag: 'U'
              },
              function() {
                scrollToComponent(this['hatchingFormView'], {
                  offset: 0,
                  align: 'top',
                  duration: 1000
                });
              }
            );
          }
        );
      })
      .catch(function(error) {
        return [];
      });
  };
  render() {
    return (
      <Fragment>
        {/* 디자인 영역 */}
        <h2>
          <Trans>Hatcher</Trans>
        </h2>

        <SettingList
          fnClickHatching={this.fnClickHatching}
          regDate={this.state.regDate}
        />

        <HatchingForm
          ref={section => {
            this.hatchingFormView = section;
          }}
          hatchingFormView={this.state.hatchingFormView}
          rowData={this.state.rowData}
          fnSetRegDate={this.fnSetRegDate}
          iuFlag={this.state.iuFlag}
          fnClickCancel={this.fnClickCancel}
          cuttingQty={this.state.cuttingQty}
        />

        <HatchingList
          regDate={this.state.regDate}
          fnSetRegDate={this.fnSetRegDate}
          fnClickModify={this.fnClickModify}
        />

        {/* 디자인 영역 */}
      </Fragment>
    );
  }
}
