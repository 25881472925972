import React, { Fragment, Component } from 'react';
import { SessionUtil } from 'utils';
import { Trans } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';
import { moment } from 'components';
import { Server } from 'components/Properties';
import i18n from 'i18next';
import { observer, inject } from 'mobx-react';
import GrowingInputForm from 'components/mobile/growing/GrowingInputForm';
import ModalContainer from 'containers/pages/mobile/common/ModalContainer';
/*
 ** [React 유의 사항 입니다]
 ** 1. <a href="#">...</a>은 <Link to="#"></Link>로 표현 부탁 드립니다.
 ** 2. <label for="">...</label>는 <label htmlFor="">...</label>으로 표현 부탁 드립니다.
 ** 3. 다국어 표현은 <Trans>글자</Trans>으로 부탁 드립니다.
 ** 4. 파일명과 클래스명을 동일하게 해 주세요.
 */

@inject((stores) => ({
  mStore: stores.MobileStore,
  mProjectStore: stores.MprojectStore,
  mGrowingStore: stores.MGrowingStore,
}))
@observer
class GrowingForm extends Component {
  componentDidMount() {
    const { mGrowingStore } = this.props;
    mGrowingStore.setWorkDate(sessionStorage.getItem('workDate'));
    window.scrollTo(0, 0); // 스크롤을 최상위로 올립니다.
    if (sessionStorage.getItem('iuFlag') == 'U') {
      this.fnSelectGrowingLog();
    }
  }

  onOpenModal = (value) => {
    const { mStore } = this.props;
    mStore.setSearchType(value);
    mStore.setModalOpen('S', null, null);
    this.fnSelectMaterial();
  };

  fnOpenConfirmModal = (value, msg) => {
    const { mStore } = this.props;
    mStore.setModalOpen('C', value, msg);
  };

  //저장 이벤트
  fnModalPos = () => {
    const { mStore } = this.props;
    mStore.setModalClose();
    this.fnInsertGrowingLog();
  };

  fnModalWarning = () => {
    const { mStore, mGrowingStore } = this.props;
    mStore.setModalClose();
    mGrowingStore.initGrowSaveForm();
    this.props.history.push('/mobile/broiler/grow');
  };

  //
  fnDeathandCullingValidation = () => {
    const { mStore, mGrowingStore } = this.props;
    if (
      Number(mGrowingStore.growSaveForm.deathQty) +
        Number(mGrowingStore.growSaveForm.cullingQty) >
      sessionStorage.getItem('prevGrowLogPopulation')
    ) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t("Sum of DeathQty and CullingQty can't over Population.")
      );
      mGrowingStore.setDeathQty(0);
      mGrowingStore.setCullingQty(0);
      mGrowingStore.setOmpalQty(0);
      mGrowingStore.setWeakQty(0);
      mGrowingStore.setAbnormalQty(0);
      mGrowingStore.setClaimQty(0);
      mGrowingStore.setDrylegQty(0);
    }
  };

  fnValidationDeathQty = (value) => {
    const { mStore, mGrowingStore } = this.props;
    this.fnDeathandCullingValidation();
    if (value < 0) {
      mStore.setModalOpen('A', null, i18n.t("DeathQty can't be minus."));
      mGrowingStore.setDeathQty(0);
    }
  };

  //비고 유효성 체크
  fnValidationRemark = (value) => {
    const { mStore, mGrowingStore } = this.props;
    var stringByteLength = 0;
    stringByteLength = value.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2')
      .length;
    if (stringByteLength > 1000) {
      mStore.setModalOpen(
        'A',
        null,
        i18n.t('Remark must be within 1000 characters. ')
      );

      mGrowingStore.setRemarks('');
    }
  };

  fnMaterialValidation = (value, type, no) => {
    const { mStore, mGrowingStore } = this.props;

    if (value < 0) {
      mStore.setModalOpen('A', null, i18n.t('Can not enter minus.'));

      if (type == 'F') {
        if (no == 1) {
          mGrowingStore.setFeedUseAmt1(0);
        }
        if (no == 2) {
          mGrowingStore.setFeedUseAmt2(0);
        }
        if (no == 3) {
          mGrowingStore.setFeedUseAmt3(0);
        }
      }

      if (type == 'V') {
        if (no == 1) {
          mGrowingStore.setVaccineUseAmt1(0);
        }
        if (no == 2) {
          mGrowingStore.setVaccineUseAmt2(0);
        }
        if (no == 3) {
          mGrowingStore.setVaccineUseAmt3(0);
        }
      }

      if (type == 'M') {
        if (no == 1) {
          mGrowingStore.setMedicineUseAmt1(0);
        }
        if (no == 2) {
          mGrowingStore.setMedicineUseAmt2(0);
        }
        if (no == 3) {
          mGrowingStore.setMedicineUseAmt3(0);
        }
      }
    } else {
      let regExp = /^\d*.?\d{0,3}$/;
      if (regExp.test(value)) {
        value.substring(0, value.length - 1);

        if (type == 'F') {
          if (no == 1) {
            mGrowingStore.setFeedUseAmt1(value);
          }
          if (no == 2) {
            mGrowingStore.setFeedUseAmt2(value);
          }
          if (no == 3) {
            mGrowingStore.setFeedUseAmt3(value);
          }
        }

        if (type == 'V') {
          if (no == 1) {
            mGrowingStore.setVaccineUseAmt1(value);
          }
          if (no == 2) {
            mGrowingStore.setVaccineUseAmt2(value);
          }
          if (no == 3) {
            mGrowingStore.setVaccineUseAmt3(value);
          }
        }

        if (type == 'M') {
          if (no == 1) {
            mGrowingStore.setMedicineUseAmt1(value);
          }
          if (no == 2) {
            mGrowingStore.setMedicineUseAmt2(value);
          }
          if (no == 3) {
            mGrowingStore.setMedicineUseAmt3(value);
          }
        }
      }
    }
  };

  fnSelectMaterial = (value) => {
    const { mStore } = this.props;
    let params = {};

    if (value != '') {
      mStore.setSearchKey(value);
    }

    params.countryCode = SessionUtil.getCountryCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.materialType = mStore.searchType;
    params.materialCode = mStore.searchKey;
    params.materialName = mStore.searchKey;
    params.pageNo = mStore.searchPageNo;
    params.pageSize = 10;

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/mobile/broiler/mSelectMaterialInfo.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then(function (response) {
        mStore.setSearchResultList(response.data.rows1);
        mStore.setSearchRowCnt(response.data.rows1.length);
        if (response.data.rows1.length > 0) {
          mStore.setSearchTotal(response.data.rows1[0].total);
        }
      })
      .catch(function (error) {
        return [];
      });
  };

  fnInsertGrowingLog = () => {
    const { mGrowingStore } = this.props;
    let params = mGrowingStore.growSaveForm;

    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.updateId = SessionUtil.getUserId();
    params.iuFlag = sessionStorage.getItem('iuFlag');
    params.projCode = SessionUtil.getProjCode();
    params.isMobile = SessionUtil.isMobile();
    console.log('saveGrowingLog', params);
    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/broiler/saveGrowingLog.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        console.log(response);
        this.props.history.push('/mobile/broiler/grow');
      })
      .catch(function (error) {
        return [];
      });
  };

  //입고수량 유효성 체크
  fnValidationQty = (value, type) => {
    const { mStore, mGrowingStore } = this.props;
    this.fnDeathandCullingValidation();
    if (value <= 0) {
      mStore.setModalOpen('A', null, i18n.t('Qty can not be minus. '));
      if (type == 'O') {
        mGrowingStore.setOmpalQty(0);
      }
      if (type == 'W') {
        mGrowingStore.setWeakQty(0);
      }
      if (type == 'D') {
        mGrowingStore.setDrylegQty(0);
      }
      if (type == 'C') {
        mGrowingStore.setClaimQty(0);
      }
      if (type == 'A') {
        mGrowingStore.setAbnormalQty(0);
      }
    }
  };

  //수정 작업시 입력된 내용 조회 API
  fnSelectGrowingLog = () => {
    let params = {};
    params.farmCode = SessionUtil.getFarmCode();
    params.dateFormat = SessionUtil.getDateFormat();
    params.projCode = SessionUtil.getProjCode();
    params.isMobile = SessionUtil.isMobile();
    params.workDate = sessionStorage.getItem('workDate');

    const url = Server.getRestAPI();
    axios({
      method: 'post',
      url: url + '/breed/broiler/selectTpBreedLog3.do',
      data: qs.stringify(params),
      withCredentials: true,
      credentials: 'same-origin',
    })
      .then((response) => {
        this.fnSelectSucceess(response.data);
      })
      .catch(function (error) {
        return [];
      });
  };

  fnSelectSucceess = (data) => {
    const { mGrowingStore } = this.props;
    mGrowingStore.setFromGrowingUpdateCommonInfo(data.rows4[0]);

    for (var i = 0; i < 3; i++) {
      switch (i) {
        case 0:
          mGrowingStore.setFromGrowingUpdateMaterial1(
            data.rows1[0],
            data.rows2[0],
            data.rows3[0]
          );
        case 1:
          mGrowingStore.setFromGrowingUpdateMaterial2(
            data.rows1[1],
            data.rows2[1],
            data.rows3[1]
          );

        case 2:
          mGrowingStore.setFromGrowingUpdateMaterial3(
            data.rows1[2],
            data.rows2[2],
            data.rows3[2]
          );
      }
    }
  };

  fnPushBack = () => {
    const { mGrowingStore } = this.props;
    mGrowingStore.initGrowSaveForm();
    this.props.history.push('/mobile/broiler/grow');
  };

  fnIsNumber = (e) => {
    let code = e.keyCode;
    if (code > 47 && code < 58) {
      return;
    }

    if (code == 8 || code == 9) {
      return;
    }
    e.preventDefault();
  };

  fnValidationUtit = (value) => {
    const { mStore, mGrowingStore } = this.props;
    if (value > 100) {
      mStore.setModalOpen('A', null, i18n.t('Uniformilty can not over 100%. '));
      mGrowingStore.setUniformity(0);
    }

    let regExp = /^\d*.?\d{0,2}$/;
    if (regExp.test(value)) {
      value.substring(0, value.length - 1);
      mGrowingStore.setUniformity(value);
    }
  };

  render() {
    const { mStore, mGrowingStore } = this.props;

    return (
      <Fragment>
        {mStore.headerYn == false ? '' : <MobileHeader mStore={mStore} />}

        <div className="pop_header">
          <div className="mback" onClick={(e) => this.fnPushBack()}>
            <Trans>back</Trans>
          </div>
          [{sessionStorage.getItem('projCode')}]
          <div
            className="mobile_header_confirm"
            onClick={(e) =>
              this.fnOpenConfirmModal(
                'S',
                i18n.t('Are you sure you wanto to save it?')
              )
            }
          >
            <Trans>Save</Trans>
          </div>
        </div>
        <div className="mobile_content_nofooter">
          <div
            className="recebox2"
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <ul>
              <li>
                <span
                  style={{
                    width:
                      localStorage.getItem('i18nextLng') == 'ko'
                        ? '15%'
                        : '38%',
                  }}
                >
                  <Trans>Last Work Date</Trans>
                </span>
                <span style={{ width: '2%' }}>(</span>
                <span style={{ width: '9%' }}>
                  <Trans>Age</Trans>
                </span>
                <span
                  style={{
                    width: '2%',
                    marginRight:
                      localStorage.getItem('i18nextLng') == 'ko'
                        ? '32%'
                        : '10%',
                  }}
                >
                  )
                </span>
                {moment(sessionStorage.getItem('prevGrowLogWorkdate')).format(
                  'YYYY[-]MM[-]DD'
                )}
                (
                {sessionStorage.getItem('prevGrowLogAgeDay') == 'undefined'
                  ? 0
                  : sessionStorage.getItem('prevGrowLogAgeDay')}
                )
              </li>
              <li>
                <span>
                  <Trans>Population</Trans>
                </span>
                {sessionStorage.getItem('prevGrowLogPopulation')}
              </li>
            </ul>
          </div>

          <GrowingInputForm
            mGrowingStore={mGrowingStore}
            mStore={mStore}
            onOpenModal={this.onOpenModal}
            fnValidationRemark={this.fnValidationRemark}
            fnMaterialValidation={this.fnMaterialValidation}
            fnValidationQty={this.fnValidationQty}
            fnValidationDeathQty={this.fnValidationDeathQty}
            fnIsNumber={this.fnIsNumber}
            fnValidationUtit={this.fnValidationUtit}
          />
        </div>

        <ModalContainer
          confirm={this.fnModalPos}
          warning={this.fnModalWarning}
          fnSelectMaterial={this.fnSelectMaterial}
        />
      </Fragment>
    );
  }
}

export default GrowingForm;
